import { DatePipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Settings } from '../models/app-settings';
import { SettingsService } from '../services/settings.service';
import { Language } from '../models/environment-config';
import { timezones } from '../models/timezone';

/**
 * transforms date value to a certain format, similar like date pipe of angular, formats to: '14:18:23'
 */
@Pipe({
  name: 'localizedDate',
  pure: false,
  standalone: true
})
export class LocalizedDatePipe implements PipeTransform {
  /**
   * timezone string
   */
  timezone: string | undefined;
  /**
   * angular datepipe variable
   */
  private datePipe: DatePipe;
  /**
   * app settings variable
   */
  private settings!: Partial<Settings>;

  /**
   * The constructor sets up app settings and timezone
   */
  constructor(private settingsService: SettingsService, @Inject(Language) private lang: string) {
    this.datePipe = new DatePipe(lang);
    this.settings = this.settingsService.settings();
    this.datePipe = new DatePipe(this.settings.languageCode as string);
    this.timezone = this.settings.timeZone;
    /*this.settingsService.settings$.subscribe({
      next: (settings) => {
        this.settings = settings;
        //if (([null, 'timeZone', 'languageCode'].includes(valueChanged)) && settings.languageCode) {
        this.datePipe = new DatePipe(settings.languageCode as string);
        this.timezone = this.settings.timeZone;
        //}
      }
    });*/
    //this.timezone = this.settings.timeZone;
  }


  /**
   * returns the formated date to string
   * @param value {any} the date time value
   * @param format {string} the format that we want the time to be outputed
   */
  transform(value: Date | string, format = 'dd-MM-yyyy, HH:mm:ss'): string | null | undefined {
    const tx = timezones[this.timezone as string];

    if (tx) {
      const timezoneRegex = /\(GMT([+-]\d+:\d+)\)/;
      const matchRegex = tx.match(timezoneRegex);

      if (matchRegex) {
        const match = matchRegex[1].replace(':', '');
        return value ? this.datePipe.transform(value, format, match, this.lang) : null;
      }


    }
    return value ? this.datePipe.transform(value, format, undefined, this.lang) : '';
  }
}
